import React, { useState, useEffect } from 'react';
import { getPropertySubTypeEnum, propertyMasterTypeEnumid } from '../model/enum';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import i18n from "../../reduxstate/i18n.js";
import { RootState } from '../../reduxstate/store';
import Loading from '../../loading';
import { Link } from 'react-router-dom';

const PopularSearches = ({ handleButtonClickpopular, listingpurposeid, loadingPopular }) => {
  const defaultimg = `${process.env.REACT_APP_Asset_URL}/img/bydefault.png`;
  const popularData = useSelector((state: RootState) => state.areas.popularAreasData);
  const webURL=process.env.REACT_APP_WEBSITE_URL;
  const queryParams = new URLSearchParams(location.search);

  const { t } = useTranslation();

  const [maxPrice, setMaxPrice] = useState<number>(175000);
  const formatString = (str) => (str || "").trim().replace(/[\/\(\)]/g, "").replace(/\s/g, "-").replace(/-+/g, "-") .toLowerCase();
  const generateURL2 = (wilayatID, areaId, wilayatName, areaName, listid,propertyMasterSubTypeID,propertyMasterTypeID) => {
    
    const pathname = listid === 1 ? 'rent' : 'sale';
    const searchParams = [];
    const getPropertyName = (itemId) => {
      switch (itemId) {
        case 1:
          return "property";
        case 2:
          return "commercial";
        case 3:
          return "residental-commercial";
        default:
          return "property";
      }
    };
     const propertySubType = getPropertySubTypeEnum(propertyMasterSubTypeID);
    // searchParams.push(`apartment`);
    if (wilayatID && areaId && wilayatName && areaName) {
      const trimmedarea = areaName.trim();
      const trimmedStr = wilayatName.trim();
      const addresss = trimmedStr.replace(/\s/g, "-").toLowerCase();
      // searchParams.push(`${addresss}`);
      searchParams.push(`${propertySubType}-in-${formatString(areaName)}`.toLowerCase());
    
      queryParams.set("type", "area")
      queryParams.set("id", areaId.toString())
    } else if (wilayatID) {
      const trimmedStr = wilayatName.trim();
      const addresss = trimmedStr.replace(/\s/g, "-");
      searchParams.push(`${propertySubType}-in-${formatString(wilayatName)}`);
      queryParams.set("type", "wilayat")
      queryParams.set("id", wilayatID.toString())
    } else {
      // searchParams.push(`oman`);
    }
    const search = searchParams.join('/');
    return `${webURL}${pathname}/${search}?${queryParams.toString()}`;
  };

  const goToPostsvillaandarea = (wilayatID, areaId, wilayatName, areaName, propertyMasterID, SubId, listid) => {
    const pathname = listid === 1 ? 'rent' : 'sale';
    const searchParams = [];
    // searchParams.push(`villa`);
    if (wilayatID && areaId && wilayatName && areaName) {
      const trimmedarea = areaName.trim();
      const trimmedStr = wilayatName.trim();
      const addresss = trimmedStr.replace(/\s/g, "-").toLowerCase();
      // searchParams.push(`${addresss}`);
      searchParams.push(`villa-in-${formatString(areaName)}`.toLowerCase());
      queryParams.set("type", "area")
      queryParams.set("id", areaId.toString())
      queryParams.delete("wilayat")
      }  else {
      // const trimmedStr = wilayatName.trim();
      // const addresss = trimmedStr.replace(/\s/g, "-").toLowerCase();
      searchParams.push(`villa-in-${formatString(wilayatName)}`);
      queryParams.set("type", "wilayat")
      queryParams.delete("area")
      queryParams.set("id", wilayatID.toString())
      
    }
    const search = searchParams.join('/');
    return `${webURL}${pathname}/${search}?${queryParams.toString()}`;
  };

  const generateURL = (wilayatID, wilayatName, listid, subid, masterid) => {
    const pathname = listid === 1 ? 'rent' : 'sale';
    const propertySubType = getPropertySubTypeEnum(subid);
    const searchParams = [];
    // if (subid) searchParams.push(``.toLowerCase());
    // searchParams.push(`property`);
    if (wilayatID) {
      const address = wilayatName.trim().replace(/\s/g, '-').toLowerCase();
      searchParams.push(`${propertySubType}-in-${formatString(wilayatName)}`.toLowerCase());
      queryParams.set("type", "wilayat")
      queryParams.set("id", wilayatID.toString())
      queryParams.delete("area")
    } else {
      // searchParams.push('oman');
    }
   ;
    const search = searchParams.join('/');
    return `${webURL}${pathname}/${search}?${queryParams.toString()}`;
  };

  const generateSchema = (item) => {
    return {
      "@context": "https://schema.org",
      "@type": "Residence",
      "name": `Property in ${item.wilayatName}`,
      "image": defaultimg,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": item.wilayatName,
        "addressLocality": "Muscat",
        "addressRegion": "Muscat Governorate",
        "postalCode": "121",
        "addressCountry": "Oman"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 21.492277944040087, // Use actual latitude
        "longitude": 55.95424600577483 // Use actual longitude
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "OMR",
        "price": 25,
        "availability": "https://schema.org/InStock"
      },
      "numberOfRooms": 1,
      "numberOfBathroomsTotal": 1,
      "floorSize": {
        "@type": "QuantitativeValue",
        "value": 10,
        "unitCode": "SQM"
      }
    };
  };

  useEffect(() => {
    if (popularData && Array.isArray(popularData)) {
      popularData.forEach((item, index) => {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(generateSchema(item));
        script.id = `schema-${index}`;
        document.head.appendChild(script);

        return () => {
          document.getElementById(`schema-${index}`)?.remove();
        };
      });
    }
  }, [popularData, listingpurposeid]);

  const renderPopularItems = (items) => {
    return items.map((item) => (
      <div key={item.wilayatId} className="col-md-4">
        <div className='w-600 font-family-poppins pb-2 omantext text-start'>Villas in {item.wilayatName}</div>
        <ul className='list listpadding text-start'>
          {item.wilayatAreas.map((area) => (
            <li className='w-600 font-family-poppins listcolor' key={area.areaId} >
              <Link className='ps-0' title={`${area.desc}, Oman`} to={goToPostsvillaandarea(
                item.wilayatId, area.areaId, item.wilayatName, area.areaName, item.propertyMasterTypeID, item.propertyMasterSubTypeID, listingpurposeid
              )
              }>{area.desc}</Link>
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  return (

    <div className="process-section container" dir={i18n.language === 'ar' ? 'ltr' : undefined}>
      <div className="text-center">
        <h3 className="font-family-poppins pb-4 _0ff3d3ee">{t('Popular Searches in Oman')}</h3>
        <div className='pb-4 pt-2'>
          <button className={`popularsearchbtn bg-white f18 w-700 ${listingpurposeid === 1 ? 'selectedpopularsearch' : ''}`} onClick={() => handleButtonClickpopular(1, 'forRent')}>{t('For Rent')}</button>
          <button className={`popularsearchbtn bg-white f18 w-700 ${listingpurposeid === 2 ? 'selectedpopularsearch' : ''}`} onClick={() => handleButtonClickpopular(2, 'forSale')}>{t('For Sale')}</button>
        </div>
        {loadingPopular ? (<Loading />) : (
          <>
            {popularData ? (
              <div>
                <div className="row gy-5 pt-4">
                  {popularData.PopularList.map((items, index) => (
                    <div key={index} className="col-md-4">
                      <div className='w-600 font-family-poppins pb-2 omantext text-start'>Apartments in {items.wilayatName}</div>
                      <ul className='list listpadding text-start'>
                        {items.wilayatAreas.map((area, index) => (
                          <li className='w-600 font-family-poppins listcolor' key={index} >
                            <Link className='ps-0' to={generateURL2(
                              items.wilayatId, area.areaId, items.wilayatName, area.areaName, listingpurposeid,items.propertyMasterSubTypeID,items.propertyMasterTypeID
                            )} title={`${area.desc}, oman`}>{area.desc}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  {popularData.popularWilayat.wilayatList.length === 0 ? null : (
                    <div className='col-md-4'>
                      <div>
                        <div className='w-600 font-family-poppins pb-2 omantext text-start'>{popularData.popularWilayat.name}</div>
                        <ul className='list listpadding text-start'>
                          {popularData.popularWilayatList.map((doc) => (
                            <li className='w-600 font-family-poppins listcolor' key={doc.wilayatId} >
                              <Link className='ps-0' to={generateURL(
                                doc.wilayatId, doc.wilayatName, listingpurposeid, doc.propertyMasterSubTypeID, doc.propertyMasterTypeID
                              )}
                                title={`Popular areas to buy, sell & rent in ${doc.wilayatName}, Oman`}
                              >
                                {doc.wilayatName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  {renderPopularItems(popularData.PopularVillaList)}
                  <div className='col-md-4'>
                    <div>
                      <div className='w-600 font-family-poppins pb-2 omantext text-start'>{popularData.popularVilla.name}</div>
                      <ul className='list listpadding text-start'>
                        {popularData.popularWilayatsixList.map((area) => (
                          <li className='w-600 font-family-poppins listcolor' key={area.wilayatId}>
                            <Link className='ps-0'
                               to={generateURL(
                                area.wilayatId, area.wilayatName, listingpurposeid, area.propertyMasterSubTypeID, area.propertyMasterTypeID
                              )}
                              title={`Villa ${listingpurposeid === 1 ? 'for Rent' : 'for Sale'} in ${area.wilayatName} Oman`}
                            >
                              {area.wilayatName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4'></div>
                </div>
              </div>
            ) : (
              <div className="skeletonBlock"></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(PopularSearches);
